import React, { Component } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
} from "@material-ui/core";
import validator from "validator";
import {
	countUppercases,
	countLowercases,
	countSymbols,
	countDigits,
} from "utils";
import { registerWithEmail, verifyEmail } from "api/authAPI";

const DialogStyle = {
	style: {
		backgroundColor: "#202020",
		color: "white",
	},
};
const styles = {
	errorMsg: {
		textAlign: "left",
		fontSize: "12px",
		color: "red",
	},
};

class EmailRegistration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			email: "",
			emailValidation: true,
			password: "",
			passwordValidation: true,
			passwordConfirmation: "",
			passwordConfirmationValidation: true,
			verificationCode: "",
		};
	}

	componentWillReceiveProps() {
		this.setState({
			email: "",
			emailValidation: true,
			password: "",
			passwordValidation: true,
			passwordConfirmation: "",
			passwordConfirmationValidation: true,
			verificationCode: "",
		});
	}
	async confirm() {
		const { email, password, passwordConfirmation } = this.state;

		const emailValidation = validator.isEmail(email);

		const passwordValidation =
			password.length >= 8 &&
			countUppercases(password) > 0 &&
			countLowercases(password) > 0 &&
			countSymbols(password) > 0 &&
			countDigits(password) > 0;

		const passwordConfirmationValidation =
			password.localeCompare(passwordConfirmation) === 0;
		if (
			emailValidation &&
			passwordValidation &&
			passwordConfirmationValidation
		) {
			const registrationStatus = await registerWithEmail(email, password);
			if (registrationStatus.status === 200) this.setState({ page: 1 });
		} else
			this.setState({
				emailValidation,
				passwordValidation,
				passwordConfirmationValidation,
			});
	}
	async verifyEmail() {
		const { email, verificationCode } = this.state;
		const verificationStatus = await verifyEmail(email, verificationCode);
		console.log(verificationStatus);
	}
	render() {
		const {
			page,
			email,
			emailValidation,
			password,
			passwordValidation,
			passwordConfirmation,
			passwordConfirmationValidation,
			verificationCode,
		} = this.state;
		const { open, onClose } = this.props;

		return (
			<Dialog open={open} PaperProps={DialogStyle}>
				<DialogTitle>
					<div className="popup-header">
						<img
							src={require("assets/images/authbtns/auth-email.svg")}
							alt="metamask"
							style={{ width: "50px", margin: "auto" }}
						/>
					</div>
				</DialogTitle>
				<DialogContent>
					{page === 0 && false ? (
						<div
							style={{
								textAlign: "center",
								display: "flex",
								flexDirection: "column",
								width: "250px",
							}}
						>
							<input
								className={
									emailValidation ? "defaultInput" : "defaultInput errorInput"
								}
								placeholder="Email"
								value={email}
								onChange={(e) =>
									this.setState({
										email: e.target.value,
										emailValidation: true,
									})
								}
							/>
							{!emailValidation && (
								<p style={styles.errorMsg}>*Email is not valid</p>
							)}
							<input
								className={
									passwordValidation
										? "defaultInput"
										: "defaultInput errorInput"
								}
								placeholder="Password"
								type="password"
								value={password}
								onChange={(e) =>
									this.setState({
										password: e.target.value,
										passwordValidation: true,
									})
								}
							/>
							{!passwordValidation && (
								<p style={styles.errorMsg}>
									*Password should contain at least 8 characters, 1 lowercase, 1
									uppercase, 1 number and 1 symbol
								</p>
							)}
							<input
								className={
									passwordConfirmationValidation
										? "defaultInput"
										: "defaultInput errorInput"
								}
								placeholder="Confirm Password"
								type="password"
								value={passwordConfirmation}
								onChange={(e) =>
									this.setState({
										passwordConfirmation: e.target.value,
										passwordConfirmationValidation: true,
									})
								}
							/>
							{!passwordConfirmationValidation && (
								<p style={styles.errorMsg}>*Password does not match</p>
							)}
						</div>
					) : (
						<div>
							<input
								className="defaultInput"
								style={{ textAlign: "center" }}
								placeholder="6 Digit Code Here"
								value={verificationCode}
								onChange={(e) =>
									this.setState({ verificationCode: e.target.value })
								}
							/>
						</div>
					)}
				</DialogContent>
				<DialogActions>
					{page === 0 ? (
						<Button style={{ color: "white" }} onClick={() => this.confirm()}>
							Sign Up
						</Button>
					) : (
						<Button
							style={{ color: "white" }}
							onClick={() => this.verifyEmail()}
						>
							Verify
						</Button>
					)}
					<Button onClick={onClose} style={{ color: "white" }}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default EmailRegistration;
