import { put, takeLatest, call } from "redux-saga/effects";
import * as ActionTypes from "store/constants";
import jwt_decode from "jwt-decode";
import { push } from "react-router-redux";

import { getMetamaskAccount, handleMetamaskSignMessage } from "api/web3client";
import { registerWithETH, signinWithETH, getUserByEthId } from "api/authAPI";

import {
	setAsRegisteringInProgress,
	setAsSigningInProgress,
	showAuthMessage,
	setAuthenticatedUser,
} from "store/actions";

//Register
function* workerRegisterWithMetamask() {
	try {
		const EthId = yield call(getMetamaskAccount);

		yield put(setAsRegisteringInProgress(true));
		const status = yield call(registerWithETH, EthId);

		if (status.status === 200) {
			yield put(setAsRegisteringInProgress(false));
			yield put(showAuthMessage("Registration Succeed!", true));
		} else {
			yield put(setAsRegisteringInProgress(false));
			if (status.status === 409)
				yield put(showAuthMessage("Account already exists.", false));
		}
	} catch (error) {
		console.error();
	}
}

//Sign In
function* workerSigninWithMetamask() {
	try {
		const EthId = yield call(getMetamaskAccount);

		yield put(setAsSigningInProgress(true));
		const user = yield call(getUserByEthId, EthId);
		if (!user.data) {
			yield put(setAsSigningInProgress(false));
			yield put(
				showAuthMessage("Account does not exists. Try register Instead", false)
			);
		} else {
			const { EthNonce } = user.data;

			const signature = yield call(handleMetamaskSignMessage, EthId, EthNonce);
			const status = yield call(signinWithETH, EthId, signature);

			yield put(setAsSigningInProgress(false));

			localStorage.setItem("jwtToken", status.data.accessToken);
			const decoded = jwt_decode(status.data.accessToken);
			yield put(setAuthenticatedUser(decoded.payload));
			yield put(push("/connections"));
		}
	} catch (error) {
		console.error();
	}
}

export default function* watchAuthSaga() {
	yield takeLatest(
		ActionTypes.REGISTER_WITH_METAMASK,
		workerRegisterWithMetamask
	);
	yield takeLatest(ActionTypes.SIGNIN_WITH_METAMASK, workerSigninWithMetamask);
}
