import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;

//ETH Connections
export const connectEth = (UserId, EthId) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/connection/connectEth`,
				{
					UserId,
					EthId,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: data.status,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};
export const disconnectEth = (UserId) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/connection/disconnectEth`,
				{
					UserId,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: data.status,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};

//WAX Connections
export const connectWax = (UserId, WaxId) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/connection/connectWax`,
				{
					UserId,
					WaxId,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: data.status,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};

export const disconnectWax = (UserId) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/connection/disconnectWax`,
				{
					UserId,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: data.status,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};
