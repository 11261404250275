import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import FadeLoader from "react-spinners/FadeLoader";

const DialogStyle = {
	style: {
		backgroundColor: "#202020",
		color: "white",
	},
};

const LoadingIndicator = ({ open, message }) => {
	return (
		<Dialog open={open} PaperProps={DialogStyle}>
			<DialogTitle style={{ overflow: "hidden" }}>
				<div style={{ marginLeft: "10px" }}>
					<FadeLoader color={"#ffffff"} />
				</div>
				{message && <p>{message}</p>}
			</DialogTitle>
		</Dialog>
	);
};

export default LoadingIndicator;
