import React, { Component } from "react";

class Terms extends Component {
	render() {
		return (
			<div className="page-container" style={{ color: "white" }}>
				<div style={{ maxWidth: "1000px", margin: "auto" }}>
					<h1>TERMS OF USE</h1>
					<h4>Last updated January 13, 2021</h4>

					<br />
					<br />
					<h2>AGREEMENT TO TERMS</h2>
					<p>
						These Terms of Use constitute a legally binding agreement made
						between you, whether personally or on behalf of an entity (“you”)
						and RedFOX Lasb ("Company", “we”, “us”, or “our”), concerning your
						access to and use of the http://account.redfoxlabs.io website as
						well as any other media form, media channel, mobile website or
						mobile application related, linked, or otherwise connected thereto
						(collectively, the “Site”). You agree that by accessing the Site,
						you have read, understood, and agreed to be bound by all of these
						Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE,
						THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
						DISCONTINUE USE IMMEDIATELY.
					</p>
					<br />
					<p>
						Supplemental terms and conditions or documents that may be posted on
						the Site from time to time are hereby expressly incorporated herein
						by reference. We reserve the right, in our sole discretion, to make
						changes or modifications to these Terms of Use at any time and for
						any reason. We will alert you about any changes by updating the
						“Last updated” date of these Terms of Use, and you waive any right
						to receive specific notice of each such change. It is your
						responsibility to periodically review these Terms of Use to stay
						informed of updates. You will be subject to, and will be deemed to
						have been made aware of and to have accepted, the changes in any
						revised Terms of Use by your continued use of the Site after the
						date such revised Terms of Use are posted.
					</p>
					<br />
					<p>
						The information provided on the Site is not intended for
						distribution to or use by any person or entity in any jurisdiction
						or country where such distribution or use would be contrary to law
						or regulation or which would subject us to any registration
						requirement within such jurisdiction or country. Accordingly, those
						persons who choose to access the Site from other locations do so on
						their own initiative and are solely responsible for compliance with
						local laws, if and to the extent local laws are applicable.
					</p>
					<br />
					<br />
					<h2>INTELLECTUAL PROPERTY RIGHTS</h2>
					<p>
						Unless otherwise indicated, the Site is our proprietary property and
						all source code, databases, functionality, software, website
						designs, audio, video, text, photographs, and graphics on the Site
						(collectively, the “Content”) and the trademarks, service marks, and
						logos contained therein (the “Marks”) are owned or controlled by us
						or licensed to us, and are protected by copyright and trademark laws
						and various other intellectual property rights and unfair
						competition laws of the United States, international copyright laws,
						and international conventions. The Content and the Marks are
						provided on the Site “AS IS” for your information and personal use
						only. Except as expressly provided in these Terms of Use, no part of
						the Site and no Content or Marks may be copied, reproduced,
						aggregated, republished, uploaded, posted, publicly displayed,
						encoded, translated, transmitted, distributed, sold, licensed, or
						otherwise exploited for any commercial purpose whatsoever, without
						our express prior written permission.
					</p>
					<br />
					<p>
						Provided that you are eligible to use the Site, you are granted a
						limited license to access and use the Site and to download or print
						a copy of any portion of the Content to which you have properly
						gained access solely for your personal, non-commercial use. We
						reserve all rights not expressly granted to you in and to the Site,
						the Content and the Marks.
					</p>
					<br />
					<br />
					<h2>PROHIBITED ACTIVITIES</h2>
					<p>
						The Site does not offer users to submit or post content. We may
						provide you with the opportunity to create, submit, post, display,
						transmit, perform, publish, distribute, or broadcast content and
						materials to us or on the Site, including but not limited to text,
						writings, video, audio, photographs, graphics, comments,
						suggestions, or personal information or other material
						(collectively, "Contributions"). Contributions may be viewable by
						other users of the Site and through third-party websites. As such,
						any Contributions you transmit may be treated in accordance with the
						Site Privacy Policy. When you create or make available any
						Contributions, you thereby represent and warrant that:
					</p>
					<br />
					<p>
						1. The creation, distribution, transmission, public display, or
						performance, and the accessing, downloading, or copying of your
						Contributions do not and will not infringe the proprietary rights,
						including but not limited to the copyright, patent, trademark, trade
						secret, or moral rights of any third party.
					</p>
					<br />
					<p>
						{" "}
						2. You are the creator and owner of or have the necessary licenses,
						rights, consents, releases, and permissions to use and to authorize
						us, the Site, and other users of the Site to use your Contributions
						in any manner contemplated by the Site and these Terms of Use.
					</p>
					<br />
					<p>
						3. You have the written consent, release, and/or permission of each
						and every identifiable individual person in your Contributions to
						use the name or likeness of each and every such identifiable
						individual person to enable inclusion and use of your Contributions
						in any manner contemplated by the Site and these Terms of Use.
					</p>
					<br />
					<p>4. Your Contributions are not false, inaccurate, or misleading.</p>
					<br />
					<p>
						5. Your Contributions are not unsolicited or unauthorized
						advertising, promotional materials, pyramid schemes, chain letters,
						spam, mass mailings, or other forms of solicitation.
					</p>
					<br />
					<p>
						6. Your Contributions are not obscene, lewd, lascivious, filthy,
						violent, harassing, libelous, slanderous, or otherwise objectionable
						(as determined by us).
					</p>
					<br />
					<p>
						7. Your Contributions do not ridicule, mock, disparage, intimidate,
						or abuse anyone.
					</p>
					<br />
					<p>
						8. Your Contributions are not used to harass or threaten (in the
						legal sense of those terms) any other person and to promote violence
						against a specific person or class of people.
					</p>
					<br />
					<p>
						9. Your Contributions do not violate any applicable law, regulation,
						or rule.
					</p>
					<br />
					<p>
						10. Your Contributions do not violate the privacy or publicity
						rights of any third party.
					</p>
					<br />
					<p>
						11. Your Contributions do not contain any material that solicits
						personal information from anyone under the age of 18.
					</p>
					<br />
					<p>
						12. Your Contributions do not violate any applicable law concerning
						child pornography, or otherwise intended to protect the health or
						well-being of minors.
					</p>
					<br />
					<p>
						13. Your Contributions do not include any offensive comments that
						are connected to race, national origin, gender, sexual preference,
						or physical handicap.
					</p>
					<br />
					<p>
						14. Your Contributions do not otherwise violate, or link to material
						that violates, any provision of these Terms of Use, or any
						applicable law or regulation.
					</p>
				</div>
			</div>
		);
	}
}

export default Terms;
