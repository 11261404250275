import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import AuthButton from "components/AuthButton";

import { signinWithMetamask, hideAuthMessage } from "store/actions";
import { isMetamaskConnected } from "api/web3client";

import MetamaskConfirmation from "./Popups/metamask";
import EmailSigninPopup from "./Popups/emailSignin";
import LoadingIndicator from "components/LoadingIndicator";

import {
	NotificationContainer,
	NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./login.scss";

class LogIn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			metamaskAccount: false,
			emailSigninPopup: false,
		};
	}

	componentDidUpdate() {
		const { authMessage } = this.props.authReducer;
		if (authMessage.message) {
			setTimeout(() => {
				this.props.hideAuthMessage();
			}, 500);
		}
	}

	async signinWithMetamask() {
		const metamaskAccount = await isMetamaskConnected();
		if (metamaskAccount) this.setState({ metamaskAccount: metamaskAccount });
		else this.props.signinWithMetamask();
	}

	render() {
		const { metamaskAccount, emailSigninPopup } = this.state;
		const { isSigning, authMessage, user } = this.props.authReducer;
		return (
			<div className="page-container">
				{!user ? (
					<div>
						<div className="mode-view">
							<img
								src={require("assets/images/image-logo.svg")}
								className="image-logo"
								alt="logo"
							/>
							<h2>Sign In to RedFOX Labs</h2>
							<div>
								{/*								<a
									href={`${
										process.env.REACT_APP_SERVER_URL
									}/auth/authWithGoogle`}
									className="disabledLink"
								>
									<AuthButton method="google" isRegister={false} />
								</a>
								<a
									href={`${
										process.env.REACT_APP_SERVER_URL
									}/auth/authWithFacebook`}
									className="disabledLink"
								>
									<AuthButton method="facebook" isRegister={false} />
								</a>*/}
								<AuthButton
									method="metamask"
									isRegister={false}
									onClick={() => this.signinWithMetamask()}
								/>
							</div>
							<br />
							<hr />
							<p>
								Not a RedFOX user yet? &nbsp;&nbsp;
								<span>
									<Link to={{ pathname: "/register" }} className="text-link">
										Sign Up
									</Link>
								</span>
							</p>
						</div>
						<div>
							<MetamaskConfirmation
								open={metamaskAccount !== false}
								account={metamaskAccount}
								onRegister={() => {
									this.setState({ metamaskAccount: false });
									this.props.signinWithMetamask();
								}}
								onClose={() => this.setState({ metamaskAccount: false })}
							/>
							<EmailSigninPopup
								open={emailSigninPopup}
								onRegister={() => {
									this.setState({ emailRegistrationPopup: false });
								}}
								onClose={() => this.setState({ emailRegistrationPopup: false })}
							/>

							<LoadingIndicator open={isSigning} />
							{authMessage.message &&
								(authMessage.succeed
									? NotificationManager.success(authMessage.message)
									: NotificationManager.error(authMessage.message))}
							<NotificationContainer />
						</div>
					</div>
				) : (
					<Redirect to={{ pathname: "/" }} />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	authReducer: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
	signinWithMetamask: () => dispatch(signinWithMetamask()),
	hideAuthMessage: () => dispatch(hideAuthMessage()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LogIn);
