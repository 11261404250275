import axios from "axios";
import config from "config";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const sendBurnEmail = (walletAddress, amount, boxType, waxAddress) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/transaction/sendBurnEmail`,
				{
					walletAddress,
					amount,
					boxType,
					waxAddress,
					tokenName: config.defaultToken,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: 200,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};
