import React from "react";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
} from "@material-ui/core";
import config from "config";

import "./inventory.scss";

const DialogStyle = {
	style: {
		backgroundColor: "#202020",
		color: "white",
	},
};

function BurnConfirmation({
	open,
	type,
	amount,
	rewardAmount,
	ethAddress,
	waxAddress,
	onConfirm,
	onClose,
}) {
	const boxName = type === "booster" ? "Booster Box" : "Starter Box";
	return (
		<Dialog onClose={onClose} open={open} PaperProps={DialogStyle}>
			<DialogTitle>
				<h2>Confirmation</h2>
			</DialogTitle>
			<DialogContent>
				<div style={{ textAlign: "center" }}>
					<p>
						{`Do you really want to burn ${amount} ${
							config.defaultToken
						} from ETH Address ${ethAddress} and get ${rewardAmount} ${boxName}${
							rewardAmount > 1 ? "es" : ""
						} on WAX address ${waxAddress}`}
						?
					</p>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onConfirm} style={{ color: "white" }}>
					Okay
				</Button>
				<Button onClick={onClose} style={{ color: "white" }}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default BurnConfirmation;
