//Authentication Actions
export const INIT_AUTHENTICATORS = "INIT_AUTHENTICATORS";

export const REGISTER_WITH_METAMASK = "REGISTER_WITH_METAMASK";
export const SIGNIN_WITH_METAMASK = "SIGNIN_WITH_METAMASK";

export const SET_AUTHENTICATED_USER = "SET_AUTHENTICATED_USER";

export const SET_AS_REGISTERING_IN_PROGRESS = "SET_AS_REGISTERING_IN_PROGRESS";
export const SET_AS_SIGNING_IN_PROGRESS = "SET_AS_SIGNING_IN_PROGRESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";

//Wallet Connections
export const CONNECT_METAMASK = "CONNECT_METAMASK";
export const DISCONNECT_METAMASK = "DISCONNECT_METAMASK";
export const SET_ETH = "SET_ETH";
export const UNSET_ETH = "UNSET_ETH";

export const CONNECT_WAX = "CONNECT_WAX";
export const DISCONNECT_WAX = "DISCONNECT_WAX";
export const SET_WAX = "SET_WAX";
export const UNSET_WAX = "UNSET_WAX";

//Transaction
export const GET_ERC20_BALANCE = "GET_ERC20_BALANCE";
export const SET_ERC20_BALANCE = "SET_ERC20_BALANCE";

export const BURN_ERC20 = "BURN_ERC20";
export const BURNING_FINISHED = "BURNING_FINISHED";
