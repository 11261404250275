export const countLowercases = (str) => {
	return (str.match(/[A-Z]/g) || []).length;
};
export const countUppercases = (str) => {
	return (str.match(/[a-z]/g) || []).length;
};
export const countSymbols = (str) => {
	return (str.match(/[~!@#$%^&*()_+{}:"<>?]/g) || []).length;
};
export const countDigits = (str) => {
	return (str.match(/\d+/g) || []).length;
};
