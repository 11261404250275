import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
	connectWAX,
	disconnectWAX,
	connectMetamask,
	disconnectMetamask,
	hideAuthMessage,
} from "store/actions";

import {
	NotificationContainer,
	NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./connections.scss";

function getAuthMethodTitle(method) {
	let methodName = "";
	switch (method) {
		case "wax":
			methodName = "WAX CLOUD WALLET";
			break;
		case "metamask":
			methodName = "METAMASK";
			break;
		default:
			break;
	}
	return methodName;
}
function ConnectionItem({ method, address, onConnect, onDisconnect }) {
	const isMobile = window.innerWidth > 480 ? false : true;
	return (
		<div className="connection-item">
			<div style={{ display: "flex" }}>
				<img
					className="logo-image"
					src={require(`assets/images/authbtns/auth-${method}.svg`)}
					alt="method"
				/>
				<div className="content-area">
					<div className="method">{getAuthMethodTitle(method)}</div>
					<div className="connection-info">
						<i
							className="fa fa-circle"
							style={address ? { color: "green" } : { color: "red" }}
						/>
						&nbsp;
						{address ? <span>{address}</span> : <span> Not Connected</span>}
					</div>
				</div>
			</div>

			<div
				className="connection-button"
				onClick={() => (address ? onDisconnect() : onConnect())}
			>
				{address ? <p>DISCONNECT</p> : !isMobile && <p>CONNECT</p>}
			</div>
		</div>
	);
}

class Connections extends Component {
	componentDidUpdate() {
		const { authMessage } = this.props.authReducer;
		if (authMessage.message) {
			setTimeout(() => {
				this.props.hideAuthMessage();
			}, 1000);
		}
	}

	render() {
		const { authMessage, user } = this.props.authReducer;

		return (
			<div className="page-container">
				{user ? (
					<div className="page-viewport-area">
						<h1 style={{ fontSize: "26px" }}>CONNECTIONS</h1>

						<div className="wallet-view">
							<ConnectionItem
								method="metamask"
								address={user.EthId}
								onConnect={() => this.props.connectMetamask()}
								onDisconnect={() => this.props.disconnectMetamask()}
							/>
							<hr />
							<ConnectionItem
								method="wax"
								address={user.WaxId}
								onConnect={() => this.props.connectWAX()}
								onDisconnect={() => this.props.disconnectWAX()}
							/>
						</div>
					</div>
				) : (
					<Redirect to={{ pathname: "/notfound" }} />
				)}
				<div>
					{authMessage.message &&
						(authMessage.succeed
							? NotificationManager.success(authMessage.message)
							: NotificationManager.error(authMessage.message))}
					<NotificationContainer />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	authReducer: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
	connectMetamask: () => dispatch(connectMetamask()),
	disconnectMetamask: () => dispatch(disconnectMetamask()),
	connectWAX: () => dispatch(connectWAX()),
	disconnectWAX: () => dispatch(disconnectWAX()),
	hideAuthMessage: () => dispatch(hideAuthMessage()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Connections);
