import React from "react";
import { Link } from "react-router-dom";

import "./footer.scss";

function Footer() {
	return (
		<div className="footer-container">
			<Link to={{ pathname: "/privacy-policy" }}>Privacy Policy</Link>
			&nbsp;&nbsp;&nbsp;
			<Link to={{ pathname: "/terms" }}>Term Of Service</Link>
		</div>
	);
}

export default Footer;
