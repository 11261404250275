import * as ActionTypes from "store/constants";

export const getERC20TokenBalance = (walletAddress, tokenName) => {
	return {
		type: ActionTypes.GET_ERC20_BALANCE,
		walletAddress,
		tokenName,
	};
};
export const setERC20TokenBalance = (payload) => {
	return {
		type: ActionTypes.SET_ERC20_BALANCE,
		payload,
	};
};

export const burnERC20Token = (walletAddress, amount, boxType, waxAddress) => {
	return {
		type: ActionTypes.BURN_ERC20,
		walletAddress,
		amount,
		boxType,
		waxAddress,
	};
};
export const burningFinished = () => {
	return {
		type: ActionTypes.BURNING_FINISHED,
	};
};
