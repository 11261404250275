import { all, fork } from "redux-saga/effects";

import watchAuthSaga from "./authSaga";
import watchConnectionSaga from "./connectionSaga";
import watchTransactionSaga from "./transactionSaga";

export default function* root() {
	yield all([
		fork(watchAuthSaga),
		fork(watchConnectionSaga),
		fork(watchTransactionSaga),
	]);
}
