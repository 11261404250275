import React from "react";

import { TextField, Button } from "@material-ui/core";
import "./inventory.scss";

function Box({
	type,
	available,
	setAmount,
	waxAddress,
	onChange,
	onChangeAddress,
	onBurn,
}) {
	const title =
		type === "booster"
			? "Collector’s Booster Box: 30 Packs"
			: "Collector’s Starter Box: 30 Starter Packs";
	const name = type === "booster" ? "Booster Box" : "Starter Box";
	return (
		<div className="box-item">
			<h3>{title}</h3>
			<img
				src={require(`assets/images/box/${type}.png`)}
				alt="box-animation"
				style={{ width: "100%" }}
			/>
			<hr />
			<p>
				{`You can get maximum ${available} ${name}${available > 1 ? "es" : ""}`}
			</p>
			<br />
			<div style={{ display: "flex", justifyContent: "center" }}>
				<TextField
					value={setAmount}
					onChange={(e) => onChange(e.target.value)}
					InputProps={{
						style: {
							color: "white",
							width: "50px",
							borderBottom: "1px solid white",
						},
					}}
				/>
				&nbsp; <p style={{ lineHeight: "30px" }}>to WAX account</p> &nbsp;&nbsp;
				<TextField
					placeholder="WAX address"
					value={waxAddress}
					onChange={(e) => onChangeAddress(e.target.value)}
					InputProps={{
						style: {
							color: "white",
							width: "100px",
							borderBottom: "1px solid white",
						},
					}}
				/>
			</div>
			<br />
			<Button
				color="secondary"
				size="small"
				variant="outlined"
				onClick={onBurn}
			>
				OPEN BOX
			</Button>
		</div>
	);
}

export default Box;
