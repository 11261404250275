import * as waxjs from "@waxio/waxjs/dist";

export function getAuthenticators(ual) {
	const authenticators = {};
	if (ual) {
		for (const chainName in ual) {
			authenticators[chainName] = ual[
				chainName
			].getAuthenticators().availableAuthenticators;
		}
	}
	return authenticators;
}

export async function loginWax(authenticator) {
	const authenticatorName = authenticator.getStyle().text;
	let users;

	try {
		users = await authenticator.login();

		if (!users || !users.length) {
			return;
		}

		let accountNameOptions = [];
		if (users.length > 1) {
			accountNameOptions = users.map((u) => {
				return { actor: u.accountName, permission: u.permission };
			});
			return;
		}

		const WaxId = await users[0].getAccountName();
		return {
			WaxId,
			accountNameOptions,
			authenticatorName,
		};
		/*
      
      this.saveSession(accountName, authenticatorName, this.getCurrentNetwork)*/
	} catch (err) {
		console.log(err);
	}
	return 1;
}

const wax = new waxjs.WaxJS("https://wax.greymass.com", null, null, false);

export async function autoLogin() {
	let isAutoLoginAvailable = await wax.isAutoLoginAvailable();
	if (isAutoLoginAvailable) {
		let userAccount = wax.userAccount;
		let pubKeys = wax.pubKeys;
		return {
			userAccount,
			pubKeys,
		};
	} else {
		return false;
	}
}

export async function login() {
	try {
		let userAccount = await wax.login();
		let pubKeys = wax.pubKeys;
		return {
			userAccount,
			pubKeys,
		};
	} catch (e) {
		return false;
	}
}

export async function sign() {
	try {
		const result = await wax.api.transact(
			{
				actions: [
					{
						account: "eosio",
						name: "delegatebw",
						authorization: [
							{
								actor: wax.userAccount,
								permission: "active",
							},
						],
						data: {
							from: wax.userAccount,
							receiver: wax.userAccount,
							//							stake_net_quantity: "0.00000001 WAX",
							//							stake_cpu_quantity: "0.00000000 WAX",
							transfer: false,
							memo: "This is a WaxJS/Cloud Wallet Demo.",
						},
					},
				],
			},
			{
				blocksBehind: 3,
				expireSeconds: 30,
			}
		);
		return result;
	} catch (e) {
		console.log(e);
		return false;
	}
}
