import React, { Component } from "react";

class PrivacyPolicy extends Component {
	render() {
		return (
			<div className="page-container" style={{ color: "white" }}>
				<div style={{ maxWidth: "1000px", margin: "auto" }}>
					<h1>PRIVACY NOTICE</h1>
					<h4>Last updated January 13, 2021</h4>
					<p>
						Thank you for choosing to be part of our community at RedFOX Labs
						("Company", "we", "us", "our"). We are committed to protecting your
						personal information and your right to privacy. If you have any
						questions or concerns about this privacy notice, or our practices
						with regards to your personal information, please contact us at
						account@redfoxlabs.io.
					</p>
					<br />
					<p>
						When you visit our website{" "}
						<a href="https://www.redfoxlabs.io/contact">
							https://www.redfoxlabs.io/contact
						</a>
						(the "Website"), and more generally, use any of our services (the
						"Services", which include the Website), we appreciate that you are
						trusting us with your personal information. We take your privacy
						very seriously. In this privacy notice, we seek to explain to you in
						the clearest way possible what information we collect, how we use it
						and what rights you have in relation to it. We hope you take some
						time to read through it carefully, as it is important. If there are
						any terms in this privacy notice that you do not agree with, please
						discontinue use of our Services immediately.
					</p>
					<br />
					<p>
						This privacy notice applies to all information collected through our
						Services (which, as described above, includes our Website), as well
						as, any related services, sales, marketing or events.
					</p>
					<br />
					<p>
						Please read this privacy notice carefully as it will help you
						understand what we do with the information that we collect.
					</p>

					<br />
					<br />
					<h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
					<h3>Personal information you disclose to us</h3>
					<p>
						In Short: We collect personal information that you provide to us.
					</p>
					<br />
					<p>
						We collect personal information that you voluntarily provide to us
						when you register on the Website, express an interest in obtaining
						information about us or our products and Services, when you
						participate in activities on the Website or otherwise when you
						contact us.
					</p>
					<br />
					<p>
						The personal information that we collect depends on the context of
						your interactions with us and the Website, the choices you make and
						the products and features you use. The personal information we
						collect may include the following:
					</p>
					<br />
					<p>
						Personal Information Provided by You. We collect names; email
						addresses; usernames; passwords; contact or authentication data; and
						other similar information.
					</p>
					<br />
					<p>
						Social Media Login Data. We may provide you with the option to
						register with us using your existing social media account details,
						like your Facebook, Twitter or other social media account.
					</p>

					<br />
					<br />
					<h2>2. HOW DO WE USE YOUR INFORMATION?</h2>
					<p>
						In Short: We process your information for purposes based on
						legitimate business interests, the fulfillment of our contract with
						you, compliance with our legal obligations, and/or your consent.
					</p>
					<br />
					<p>
						We use personal information collected via our Website for a variety
						of business purposes described below. We process your personal
						information for these purposes in reliance on our legitimate
						business interests, in order to enter into or perform a contract
						with you, with your consent, and/or for compliance with our legal
						obligations. We indicate the specific processing grounds we rely on
						next to each purpose listed below.
					</p>
					<br />
					<p>We use the information we collect or receive:</p>
					<br />
					<p>
						- To facilitate account creation and logon process. If you choose to
						link your account with us to a third-party account (such as your
						Google or Facebook account), we use the information you allowed us
						to collect from those third parties to facilitate account creation
						and logon process for the performance of the contract.
					</p>
					<br />
					<p>
						- To post testimonials. We post testimonials on our Website that may
						contain personal information. Prior to posting a testimonial, we
						will obtain your consent to use your name and the content of the
						testimonial. If you wish to update, or delete your testimonial,
						please contact us at&nbsp;
						<a href="mailto:account@redfoxlabs.io">account@redfoxlabs.io</a> and
						be sure to include your name, testimonial location, and contact
						information.
					</p>
					<br />
					<p>
						- Request feedback. We may use your information to request feedback
						and to contact you about your use of our Website.
					</p>
					<br />
					<p>
						- To enable user-to-user communications. We may use your information
						in order to enable user-to-user communications with each user's
						consent.
					</p>
					<br />
					<p>
						- To manage user accounts. We may use your information for the
						purposes of managing our account and keeping it in working order.
					</p>

					<br />
					<br />
					<h2>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
					<p>
						In Short: We only share information with your consent, to comply
						with laws, to provide you with services, to protect your rights, or
						to fulfill business obligations.
					</p>
					<br />
					<p>
						We may process or share your data that we hold based on the
						following legal basis:
					</p>
					<br />
					<p>
						- Consent: We may process your data if you have given us specific
						consent to use your personal information for a specific purpose.
					</p>
					<br />
					<p>
						- Legitimate Interests: We may process your data when it is
						reasonably necessary to achieve our legitimate business interests.
					</p>
					<br />
					<p>
						- Performance of a Contract: Where we have entered into a contract
						with you, we may process your personal information to fulfill the
						terms of our contract.
					</p>
					<br />
					<p>
						- Legal Obligations: We may disclose your information where we are
						legally required to do so in order to comply with applicable law,
						governmental requests, a judicial proceeding, court order, or legal
						process, such as in response to a court order or a subpoena
						(including in response to public authorities to meet national
						security or law enforcement requirements).
					</p>
					<br />
					<p>
						- Vital Interests: We may disclose your information where we believe
						it is necessary to investigate, prevent, or take action regarding
						potential violations of our policies, suspected fraud, situations
						involving potential threats to the safety of any person and illegal
						activities, or as evidence in litigation in which we are involved.
					</p>

					<br />
					<br />
					<h2>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </h2>
					<p>
						In Short: If you choose to register or log in to our services using
						a social media account, we may have access to certain information
						about you.
					</p>
					<br />
					<p>
						Our Website offers you the ability to register and login using your
						third-party social media account details (like your Facebook or
						Twitter logins). Where you choose to do this, we will receive
						certain profile information about you from your social media
						provider. The profile Information we receive may vary depending on
						the social media provider concerned, but will often include your
						name, email address, friends list, profile picture as well as other
						information you choose to make public on such social media platform.
					</p>
					<br />
					<p>
						We will use the information we receive only for the purposes that
						are described in this privacy notice or that are otherwise made
						clear to you on the relevant Website. Please note that we do not
						control, and are not responsible for, other uses of your personal
						information by your third-party social media provider. We recommend
						that you review their privacy notice to understand how they collect,
						use and share your personal information, and how you can set your
						privacy preferences on their sites and apps.
					</p>

					<br />
					<br />
					<h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
					<p>
						In Short: We keep your information for as long as necessary to
						fulfill the purposes outlined in this privacy notice unless
						otherwise required by law.
					</p>
					<br />
					<p>
						We will only keep your personal information for as long as it is
						necessary for the purposes set out in this privacy notice, unless a
						longer retention period is required or permitted by law (such as
						tax, accounting or other legal requirements). No purpose in this
						notice will require us keeping your personal information for longer
						than .
					</p>
					<br />
					<p>
						When we have no ongoing legitimate business need to process your
						personal information, we will either delete or anonymize such
						information, or, if this is not possible (for example, because your
						personal information has been stored in backup archives), then we
						will securely store your personal information and isolate it from
						any further processing until deletion is possible.
					</p>

					<br />
					<br />
					<h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
					<p>
						In Short: We aim to protect your personal information through a
						system of organizational and technical security measures.
					</p>
					<br />
					<p>
						We have implemented appropriate technical and organizational
						security measures designed to protect the security of any personal
						information we process. However, despite our safeguards and efforts
						to secure your information, no electronic transmission over the
						Internet or information storage technology can be guaranteed to be
						100% secure, so we cannot promise or guarantee that hackers,
						cybercriminals, or other unauthorized third parties will not be able
						to defeat our security, and improperly collect, access, steal, or
						modify your information. Although we will do our best to protect
						your personal information, transmission of personal information to
						and from our Website is at your own risk. You should only access the
						Website within a secure environment.
					</p>

					<br />
					<br />
					<h2>7. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </h2>
					<p>
						If you have questions or comments about this notice, you may email
						us at{" "}
						<a href="mailto:account@redfoxlabs.io">account@redfoxlabs.io</a> or
						by post to:
					</p>
					<br />
					<p>
						RedFOX Labs Thảo Điền,
						<br /> Quận 2, Hồ Chí Minh,
						<br /> Vietnam
					</p>
				</div>
			</div>
		);
	}
}

export default PrivacyPolicy;
