import React from "react";

const styles = {
	parent: {
		width: "40px",
		padding: "13px 9px",
		margin: "16px 0px",
		boxSizing: "border-box",
	},
	mdiv: {
		height: "25px",
		width: "2px",
		marginLeft: "12px",
		backgroundColor: "red",
		transform: "rotate(45deg)",
		zIndex: "1",
	},
	md: {
		height: "25px",
		width: "2px",
		backgroundColor: "red",
		transform: "rotate(90deg)",
		zIndex: "2",
	},
};
function CloseButton({ onClick }) {
	return (
		<div style={styles.parent} onClick={() => onClick()}>
			<div style={styles.mdiv}>
				<div style={styles.md} />
			</div>
		</div>
	);
}

export default CloseButton;
