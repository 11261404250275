const config = {
	defaultToken: "2ECT",
	tokens: [
		{
			name: "RFOX",
			tokenAddress: "0xa1d6df714f91debf4e0802a542e13067f31b8262",
			ABI: [
				{
					inputs: [
						{
							components: [
								{ internalType: "address", name: "account", type: "address" },
								{ internalType: "uint256", name: "amount", type: "uint256" },
							],
							internalType: "struct Token.InitialAllocations[]",
							name: "_initialAllocations",
							type: "tuple[]",
						},
					],
					stateMutability: "nonpayable",
					type: "constructor",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "address",
							name: "owner",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							indexed: false,
							internalType: "uint256",
							name: "value",
							type: "uint256",
						},
					],
					name: "Approval",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "address",
							name: "from",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "to",
							type: "address",
						},
						{
							indexed: false,
							internalType: "uint256",
							name: "value",
							type: "uint256",
						},
					],
					name: "Transfer",
					type: "event",
				},
				{
					inputs: [
						{ internalType: "address", name: "owner", type: "address" },
						{ internalType: "address", name: "spender", type: "address" },
					],
					name: "allowance",
					outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{ internalType: "address", name: "spender", type: "address" },
						{ internalType: "uint256", name: "amount", type: "uint256" },
					],
					name: "approve",
					outputs: [{ internalType: "bool", name: "", type: "bool" }],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{ internalType: "address", name: "account", type: "address" },
					],
					name: "balanceOf",
					outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "decimals",
					outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{ internalType: "address", name: "spender", type: "address" },
						{
							internalType: "uint256",
							name: "subtractedValue",
							type: "uint256",
						},
					],
					name: "decreaseAllowance",
					outputs: [{ internalType: "bool", name: "", type: "bool" }],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{ internalType: "address", name: "spender", type: "address" },
						{ internalType: "uint256", name: "addedValue", type: "uint256" },
					],
					name: "increaseAllowance",
					outputs: [{ internalType: "bool", name: "", type: "bool" }],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [],
					name: "name",
					outputs: [{ internalType: "string", name: "", type: "string" }],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "symbol",
					outputs: [{ internalType: "string", name: "", type: "string" }],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "totalSupply",
					outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{ internalType: "address", name: "recipient", type: "address" },
						{ internalType: "uint256", name: "amount", type: "uint256" },
					],
					name: "transfer",
					outputs: [{ internalType: "bool", name: "", type: "bool" }],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{ internalType: "address", name: "sender", type: "address" },
						{ internalType: "address", name: "recipient", type: "address" },
						{ internalType: "uint256", name: "amount", type: "uint256" },
					],
					name: "transferFrom",
					outputs: [{ internalType: "bool", name: "", type: "bool" }],
					stateMutability: "nonpayable",
					type: "function",
				},
			],
			decimals: 18,
		},
		{
			name: "2ECT",
			tokenAddress: "0xb70c5f7e831bf9a13a01214225f36ca44c91d0ec",
			ABI: [
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "address",
							name: "owner",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							indexed: false,
							internalType: "uint256",
							name: "value",
							type: "uint256",
						},
					],
					name: "Approval",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "Paused",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							indexed: true,
							internalType: "bytes32",
							name: "previousAdminRole",
							type: "bytes32",
						},
						{
							indexed: true,
							internalType: "bytes32",
							name: "newAdminRole",
							type: "bytes32",
						},
					],
					name: "RoleAdminChanged",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							indexed: true,
							internalType: "address",
							name: "account",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "sender",
							type: "address",
						},
					],
					name: "RoleGranted",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							indexed: true,
							internalType: "address",
							name: "account",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "sender",
							type: "address",
						},
					],
					name: "RoleRevoked",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "address",
							name: "from",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "to",
							type: "address",
						},
						{
							indexed: false,
							internalType: "uint256",
							name: "value",
							type: "uint256",
						},
					],
					name: "Transfer",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "Unpaused",
					type: "event",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "approve",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "burn",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "burnFrom",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "subtractedValue",
							type: "uint256",
						},
					],
					name: "decreaseAllowance",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "grantRole",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "addedValue",
							type: "uint256",
						},
					],
					name: "increaseAllowance",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "to",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "mint",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [],
					name: "pause",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "renounceRole",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "revokeRole",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "recipient",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "transfer",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "sender",
							type: "address",
						},
						{
							internalType: "address",
							name: "recipient",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "transferFrom",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [],
					name: "unpause",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "string",
							name: "name",
							type: "string",
						},
						{
							internalType: "string",
							name: "symbol",
							type: "string",
						},
					],
					stateMutability: "nonpayable",
					type: "constructor",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "owner",
							type: "address",
						},
						{
							internalType: "address",
							name: "spender",
							type: "address",
						},
					],
					name: "allowance",
					outputs: [
						{
							internalType: "uint256",
							name: "",
							type: "uint256",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "balanceOf",
					outputs: [
						{
							internalType: "uint256",
							name: "",
							type: "uint256",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "decimals",
					outputs: [
						{
							internalType: "uint8",
							name: "",
							type: "uint8",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "DEFAULT_ADMIN_ROLE",
					outputs: [
						{
							internalType: "bytes32",
							name: "",
							type: "bytes32",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
					],
					name: "getRoleAdmin",
					outputs: [
						{
							internalType: "bytes32",
							name: "",
							type: "bytes32",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "uint256",
							name: "index",
							type: "uint256",
						},
					],
					name: "getRoleMember",
					outputs: [
						{
							internalType: "address",
							name: "",
							type: "address",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
					],
					name: "getRoleMemberCount",
					outputs: [
						{
							internalType: "uint256",
							name: "",
							type: "uint256",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "hasRole",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "MINTER_ROLE",
					outputs: [
						{
							internalType: "bytes32",
							name: "",
							type: "bytes32",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "name",
					outputs: [
						{
							internalType: "string",
							name: "",
							type: "string",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "paused",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "PAUSER_ROLE",
					outputs: [
						{
							internalType: "bytes32",
							name: "",
							type: "bytes32",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "symbol",
					outputs: [
						{
							internalType: "string",
							name: "",
							type: "string",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "totalSupply",
					outputs: [
						{
							internalType: "uint256",
							name: "",
							type: "uint256",
						},
					],
					stateMutability: "view",
					type: "function",
				},
			],
			decimals: 18,
		},
		{
			name: "CyberC",
			tokenAddress: "0xFE53830BCD5448566d8A8E819387F0104A0e527B",
			ABI: [
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "address",
							name: "owner",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							indexed: false,
							internalType: "uint256",
							name: "value",
							type: "uint256",
						},
					],
					name: "Approval",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "Paused",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							indexed: true,
							internalType: "bytes32",
							name: "previousAdminRole",
							type: "bytes32",
						},
						{
							indexed: true,
							internalType: "bytes32",
							name: "newAdminRole",
							type: "bytes32",
						},
					],
					name: "RoleAdminChanged",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							indexed: true,
							internalType: "address",
							name: "account",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "sender",
							type: "address",
						},
					],
					name: "RoleGranted",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							indexed: true,
							internalType: "address",
							name: "account",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "sender",
							type: "address",
						},
					],
					name: "RoleRevoked",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: "address",
							name: "from",
							type: "address",
						},
						{
							indexed: true,
							internalType: "address",
							name: "to",
							type: "address",
						},
						{
							indexed: false,
							internalType: "uint256",
							name: "value",
							type: "uint256",
						},
					],
					name: "Transfer",
					type: "event",
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "Unpaused",
					type: "event",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "approve",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "burn",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "burnFrom",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "subtractedValue",
							type: "uint256",
						},
					],
					name: "decreaseAllowance",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "grantRole",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "spender",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "addedValue",
							type: "uint256",
						},
					],
					name: "increaseAllowance",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "to",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "mint",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [],
					name: "pause",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "renounceRole",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "revokeRole",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "recipient",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "transfer",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "sender",
							type: "address",
						},
						{
							internalType: "address",
							name: "recipient",
							type: "address",
						},
						{
							internalType: "uint256",
							name: "amount",
							type: "uint256",
						},
					],
					name: "transferFrom",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [],
					name: "unpause",
					outputs: [],
					stateMutability: "nonpayable",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "string",
							name: "name",
							type: "string",
						},
						{
							internalType: "string",
							name: "symbol",
							type: "string",
						},
					],
					stateMutability: "nonpayable",
					type: "constructor",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "owner",
							type: "address",
						},
						{
							internalType: "address",
							name: "spender",
							type: "address",
						},
					],
					name: "allowance",
					outputs: [
						{
							internalType: "uint256",
							name: "",
							type: "uint256",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "balanceOf",
					outputs: [
						{
							internalType: "uint256",
							name: "",
							type: "uint256",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "decimals",
					outputs: [
						{
							internalType: "uint8",
							name: "",
							type: "uint8",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "DEFAULT_ADMIN_ROLE",
					outputs: [
						{
							internalType: "bytes32",
							name: "",
							type: "bytes32",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
					],
					name: "getRoleAdmin",
					outputs: [
						{
							internalType: "bytes32",
							name: "",
							type: "bytes32",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "uint256",
							name: "index",
							type: "uint256",
						},
					],
					name: "getRoleMember",
					outputs: [
						{
							internalType: "address",
							name: "",
							type: "address",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
					],
					name: "getRoleMemberCount",
					outputs: [
						{
							internalType: "uint256",
							name: "",
							type: "uint256",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [
						{
							internalType: "bytes32",
							name: "role",
							type: "bytes32",
						},
						{
							internalType: "address",
							name: "account",
							type: "address",
						},
					],
					name: "hasRole",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "MINTER_ROLE",
					outputs: [
						{
							internalType: "bytes32",
							name: "",
							type: "bytes32",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "name",
					outputs: [
						{
							internalType: "string",
							name: "",
							type: "string",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "paused",
					outputs: [
						{
							internalType: "bool",
							name: "",
							type: "bool",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "PAUSER_ROLE",
					outputs: [
						{
							internalType: "bytes32",
							name: "",
							type: "bytes32",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "symbol",
					outputs: [
						{
							internalType: "string",
							name: "",
							type: "string",
						},
					],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "totalSupply",
					outputs: [
						{
							internalType: "uint256",
							name: "",
							type: "uint256",
						},
					],
					stateMutability: "view",
					type: "function",
				},
			],
			decimals: 18,
		},
	],
};
export default config;
