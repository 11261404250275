import React, { Component } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

import { setAuthenticatedUser } from "store/actions/authActions";

class RedirectPage extends Component {
	componentDidMount() {
		const { type, data } = this.props.match.params;
		switch (type) {
			case "auth":
				localStorage.setItem("jwtToken", data);
				const decoded = jwt_decode(data);
				this.props.setAuthenticatedUser(decoded.payload);
				this.props.history.push("/connections");
				break;
			default:
				break;
		}
	}

	render() {
		return <div className="page-container">Redirecting...</div>;
	}
}

const mapDispatchToProps = (dispatch) => ({
	setAuthenticatedUser: (authData) => dispatch(setAuthenticatedUser(authData)),
});

export default connect(
	null,
	mapDispatchToProps
)(RedirectPage);
