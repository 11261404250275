import { put, takeLatest, call } from "redux-saga/effects";
import * as ActionTypes from "store/constants";

import { sendBurnEmail } from "api/transactionAPI";
import { getERC20TokenBalance, getWeb3 } from "api/web3client";
import * as transactionAction from "store/actions/transactionActions";

import config from "config";

function* workerGetERC20TokenBalance(action) {
	try {
		const balance = yield call(
			getERC20TokenBalance,
			action.walletAddress,
			action.tokenName
		);
		yield put(transactionAction.setERC20TokenBalance(balance));
	} catch (error) {
		console.error();
	}
}

function* workerBurnERC20Token(action) {
	try {
		/*		const result = yield call(
			burnToken,
			action.walletAddress,
			config.defaultToken,
			action.amount
		);
        console.log(result);
  */

		if (action.boxType.localeCompare("booster") === 0)
			localStorage.setItem("LastBoosterWaxId", action.waxAddress);
		if (action.boxType.localeCompare("starter") === 0)
			localStorage.setItem("LastStarterWaxId", action.waxAddress);

		sendBurnEmail(
			action.walletAddress,
			action.amount,
			action.boxType,
			action.waxAddress
		);

		//WAX
		const web3 = yield call(getWeb3);

		const token = config.tokens.find(
			(token) => token.name.localeCompare(config.defaultToken) === 0
		);
		let contract = new web3.eth.Contract(token.ABI, token.tokenAddress);

		contract.methods
			.burn(web3.utils.toWei(action.amount.toString(), "ether"))
			.send({ from: action.walletAddress, gas: 200000 })
			.catch(function(e) {
				console.log("error");
				return false;
			});

		yield put(transactionAction.getERC20TokenBalance());
	} catch (error) {
		console.error();
	}
}

export default function* workTransactionSaga() {
	yield takeLatest(ActionTypes.GET_ERC20_BALANCE, workerGetERC20TokenBalance);
	yield takeLatest(ActionTypes.BURN_ERC20, workerBurnERC20Token);
}
