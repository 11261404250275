export const getFileExtension = (filename) => {
	return filename.split(".").pop();
};
export const splitEndpoint = (endpoint) => {
	const [protocol, hostPort] = endpoint.split("://");
	const [host, portStr] = hostPort.split(":");
	let port = parseInt(portStr);
	if (isNaN(port)) {
		port = protocol === "https" ? 443 : 80;
	}

	return {
		protocol,
		host,
		port,
	};
};
