import React, { Component } from "react";

import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";

import store, { history } from "./store/store.js";
import jwt_decode from "jwt-decode";

import ReactGA from "react-ga";

import Header from "components/Header";
import Footer from "components/Footer";
import Home from "screens/Home";
import Register from "screens/Register";
import LogIn from "screens/LogIn";
import Connections from "screens/Connections";
import Inventory from "screens/Inventory";
import Dashboard from "screens/Dashboard";
import PrivacyPolicy from "screens/ExtraPages/PrivacyPolicy";
import Terms from "screens/ExtraPages/Terms";
import NotFoundPage from "screens/ExtraPages/404";
import RedirectPage from "screens/ExtraPages/RedirectPage";

import {
	initAuthenticators,
	setAuthenticatedUser,
	signout,
} from "store/actions";

import "font-awesome/css/font-awesome.min.css";
import "./App.scss";

if (localStorage.jwtToken) {
	const token = localStorage.jwtToken;

	let decoded = jwt_decode(token);
	if (localStorage.LastBoosterWaxId)
		decoded.payload.LastBoosterWaxId = localStorage.LastBoosterWaxId;
	if (localStorage.LastStarterWaxId)
		decoded.payload.LastStarterWaxId = localStorage.LastStarterWaxId;

	store.dispatch(setAuthenticatedUser(decoded.payload));

	const currentTime = Date.now() / 1000;

	if (decoded.exp < currentTime) {
		store.dispatch(signout());
		window.location.href = "/signin";
	}
}

ReactGA.initialize("UA-133578318-2");

history.listen((location) => {
	//Google Analyse
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

export default class App extends Component {
	componentDidMount() {
		store.dispatch(initAuthenticators());
	}

	render() {
		return (
			<Provider store={store}>
				<Router history={history}>
					<div>
						<Header />
						<div>
							<Switch>
								<Route exact path="/" component={Home} />
								<Route path="/register" component={Register} />
								<Route path="/signin" component={LogIn} />
								<Route path="/connections" component={Connections} />
								<Route path="/dashboard" component={Dashboard} />
								<Route path="/inventory" component={Inventory} />
								<Route path="/privacy-policy" component={PrivacyPolicy} />
								<Route path="/terms" component={Terms} />
								<Route path="/redirect/:type/:data" component={RedirectPage} />
								<Route path="*" component={NotFoundPage} />
							</Switch>
						</div>
						<Footer />
					</div>
				</Router>
			</Provider>
		);
	}
}
