import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { signout } from "store/actions";

import MobileMenu from "./mobileMenu";
import BurgerButton from "components/BurgerButton";
import CloseButton from "components/CloseButton";

import "./header.scss";

function Header({ user, history, signout }) {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const isMobile = window.innerWidth > 480 ? false : true;

	const setMobileMenuStatus = (value) => {
		if (value) document.getElementById("root").classList.add("body-dimmed");
		else document.getElementById("root").classList.remove("body-dimmed");
		setMobileMenuOpen(value);
	};
	return (
		<div className="header-container">
			<Link to={{ pathname: "/" }}>
				<img
					src={require("assets/images/main-logo.svg")}
					className="main-logo"
					alt="main-logo"
				/>
			</Link>
			{!isMobile ? (
				<div className="header-menu">
					<div>
						{user && (
							<Link to={{ pathname: "/inventory" }} className="menu-item">
								INVENTORY
							</Link>
						)}
						{user && (
							<Link to={{ pathname: "/connections" }} className="menu-item">
								CONNECTIONS
							</Link>
						)}
					</div>
					<div className="menu-item">
						{!user ? (
							<Link to={{ pathname: "/register" }} className="menu-item">
								<i className="fa fa-user-circle-o" style={{ color: "red" }} />
								&nbsp;SIGN UP
							</Link>
						) : (
							<div
								className="menu-item"
								onClick={() => {
									signout();
									history.push("/");
								}}
							>
								SIGN OUT
							</div>
						)}
					</div>
				</div>
			) : mobileMenuOpen ? (
				<CloseButton onClick={() => setMobileMenuStatus(false)} />
			) : (
				<BurgerButton onClick={() => setMobileMenuStatus(true)} />
			)}
			{mobileMenuOpen && (
				<MobileMenu closeMenu={() => setMobileMenuStatus(false)} />
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
	signout: () => dispatch(signout()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Header));
