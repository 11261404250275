import React from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
} from "@material-ui/core";

const DialogStyle = {
	style: {
		backgroundColor: "#202020",
		color: "white",
	},
};

const MetamaskConfirmation = ({ open, account, onRegister, onClose }) => {
	return (
		<Dialog onClose={onClose} open={open} PaperProps={DialogStyle}>
			<DialogTitle>
				<div className="popup-header">
					<img
						src={require("assets/images/logos/metamask.svg")}
						alt="metamask"
						style={{ width: "50px", margin: "auto" }}
					/>
					<p className="popup-title">{account}</p>
				</div>
			</DialogTitle>
			<DialogContent>
				<div style={{ textAlign: "center" }}>
					<p>MetaMask is already connected to RedFOX Labs.</p>
					<p>Do you really want to sign in with this account?</p>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onRegister} style={{ color: "white" }}>
					Sign In
				</Button>
				<Button onClick={onClose} style={{ color: "white" }}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default MetamaskConfirmation;
