import * as ActionTypes from "store/constants";

export const initAuthenticators = () => {
	return {
		type: ActionTypes.INIT_AUTHENTICATORS,
	};
};

//Auth Utils
export const setAsRegisteringInProgress = (payload) => {
	return {
		type: ActionTypes.SET_AS_REGISTERING_IN_PROGRESS,
		payload,
	};
};
export const setAsSigningInProgress = (payload) => {
	return {
		type: ActionTypes.SET_AS_SIGNING_IN_PROGRESS,
		payload,
	};
};
export const showAuthMessage = (message, succeed) => {
	return {
		type: ActionTypes.SHOW_AUTH_MESSAGE,
		payload: {
			message,
			succeed,
		},
	};
};
export const hideAuthMessage = () => {
	return {
		type: ActionTypes.HIDE_AUTH_MESSAGE,
	};
};

//Auth Main
export const registerWithMetamask = () => {
	return {
		type: ActionTypes.REGISTER_WITH_METAMASK,
	};
};
export const signinWithMetamask = () => {
	return {
		type: ActionTypes.SIGNIN_WITH_METAMASK,
	};
};

//Set Authenticated User
export const setAuthenticatedUser = (payload) => {
	return {
		type: ActionTypes.SET_AUTHENTICATED_USER,
		payload,
	};
};
export const signout = () => {
	localStorage.removeItem("jwtToken");
	return {
		type: ActionTypes.SET_AUTHENTICATED_USER,
		payload: null,
	};
};
