import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;

//Metamask Authentication
export const registerWithETH = (EthId) => {
	return new Promise((resolve) => {
		axios
			.get(`${serverUrl}/auth/registerWithETH`, {
				params: { EthId },
			})
			.then((data) => {
				resolve({
					status: 200,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};

export const getUserByEthId = (EthId) => {
	return new Promise((resolve) => {
		axios
			.get(`${serverUrl}/auth/getUserByEthId`, {
				params: { EthId },
			})
			.then((data) => {
				resolve({
					status: 200,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};
export const signinWithETH = (EthId, signature) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/auth/signinWithETH`,
				{
					EthId: EthId,
					signature,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: 200,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};

//Email Authentication
export const registerWithEmail = (Email, Password) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/auth/registerWithEmail`,
				{
					Email,
					Password,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: 200,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};
export const signinWithEmail = (Email, Password) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/auth/signinWithEmail`,
				{
					Email,
					Password,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: 200,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};
export const verifyEmail = (Email, VerificationCode) => {
	return new Promise((resolve) => {
		axios
			.post(
				`${serverUrl}/auth/verifyEmail`,
				{
					Email,
					VerificationCode,
				},
				{}
			)
			.then((data) => {
				resolve({
					status: 200,
					data: data.data,
				});
			})
			.catch((err) => {
				resolve({
					status: err.response.status,
					error: err.response.data.error,
				});
			});
	});
};
