import * as ActionTypes from "store/constants";
import config from "lib/config";

import { Wax } from "@eosdacio/ual-wax";
import { UAL } from "universal-authenticator-library";
import { splitEndpoint } from "utils";

const initialState = {
	isRegistering: false,
	isSigning: false,
	authMessage: {
		message: null,
		succeed: true,
	},

	//Succeed Authentication Data
	user: null,

	//Temporary WAX Blockchain Data
	chains: [],
	authenticators: [],
	ual: {},
	waxAuthData: {
		accountname: "",
		account_selected: "",
		accountname_options: [],
	},
};

export default function setBrowserInfo(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.INIT_AUTHENTICATORS:
			const chainsWax = [
				{
					chainId: config.waxChainId,
					rpcEndpoints: [splitEndpoint(config.waxEndpoint)],
				},
			];

			const authenticatorsWax = [
				new Wax(chainsWax, { appName: config.appName }),
			];

			const chains = {
				wax: chainsWax,
			};
			const authenticators = {
				wax: authenticatorsWax,
			};

			//Generate UAL
			let ual = {};
			for (const chainName in chains) {
				ual[chainName] = new UAL(
					chains[chainName],
					config.appName,
					authenticators[chainName]
				);
			}

			return {
				...state,
				chains: chains,
				authenticators: authenticators,
				ual: ual,
			};

		//SignIn Succeed
		case ActionTypes.SET_AUTHENTICATED_USER:
			return {
				...state,
				user: action.payload,
			};
		//Utils
		case ActionTypes.SET_AS_REGISTERING_IN_PROGRESS:
			return {
				...state,
				isRegistering: action.payload,
			};
		case ActionTypes.SET_AS_SIGNING_IN_PROGRESS:
			return {
				...state,
				isSigning: action.payload,
			};
		case ActionTypes.SHOW_AUTH_MESSAGE:
			return {
				...state,
				authMessage: action.payload,
			};
		case ActionTypes.HIDE_AUTH_MESSAGE:
			return {
				...state,
				authMessage: {
					message: null,
					succeed: true,
				},
			};

		//UPDATE ETH CONNECTIONS
		case ActionTypes.SET_ETH:
			return {
				...state,
				user: {
					...state.user,
					EthId: action.EthId,
				},
			};
		case ActionTypes.UNSET_ETH:
			return {
				...state,
				user: {
					...state.user,
					EthId: null,
				},
			};

		//UPDATE WAX CONNECTIONS
		case ActionTypes.SET_WAX:
			return {
				...state,
				user: {
					...state.user,
					WaxId: action.WaxId,
				},
			};
		case ActionTypes.UNSET_WAX:
			return {
				...state,
				user: {
					...state.user,
					WaxId: null,
				},
			};
		default:
			return state;
	}
}
