import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
	getERC20TokenBalance,
	burnERC20Token,
} from "store/actions/transactionActions";
import config from "config";

import Box from "./box";
import ConfirmationPopup from "./burnConfirmation";
//import BurnSucceed from "./burnSucceed";
import LoadingIndicator from "components/LoadingIndicator";

import { getMetamaskAccount } from "api/web3client";

class Inventory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boosterAmount: 0,
			starterAmount: 0,
			boosterWaxAddress: "",
			starterWaxAddress: "",
			confirmationPopup: null,
		};
	}

	componentDidMount() {
		const { user } = this.props;
		getMetamaskAccount();
		if (user) {
			this.props.getERC20TokenBalance(user.EthId, config.defaultToken);
			this.setState({
				boosterWaxAddress: user.LastBoosterWaxId ? user.LastBoosterWaxId : "",
				starterWaxAddress: user.LastStarterWaxId ? user.LastStarterWaxId : "",
			});
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.erc20balance !== this.props.erc20balance) {
			this.initialAllocate();
		}
	}

	initialAllocate() {
		const total = this.props.erc20balance;
		const starter = parseInt(total / 4);
		const booster = total - starter * 2;
		this.setState({
			boosterAmount: booster,
			starterAmount: starter,
		});
	}

	allocateBooster(value) {
		if (value > this.props.erc20balance) return;
		const starterAmount = parseInt((this.props.erc20balance - value) / 2);
		this.setState({
			boosterAmount: value,
			starterAmount,
		});
	}
	allocateStarter(value) {
		if (value > parseInt(this.props.erc20balance / 2)) return;
		const boosterAmount = this.props.erc20balance - value * 2;
		this.setState({
			boosterAmount,
			starterAmount: value,
		});
	}

	render() {
		const { user, erc20balance, burningInProgress } = this.props;

		let availableBooster = 0,
			availableStarter = 0;
		if (erc20balance) {
			availableBooster = erc20balance;
			availableStarter = erc20balance / 2;
		}
		const {
			boosterAmount,
			starterAmount,
			boosterWaxAddress,
			starterWaxAddress,
			confirmationPopup,
		} = this.state;
		return (
			<div className="page-container">
				{user ? (
					<div className="page-viewport-area">
						<p
							style={{
								textAlign: "center",
								border: "2px dashed red",
								borderRadius: "10px",
								padding: "10px",
								fontSize: "25px",
							}}
						>
							If you haven't received your packs in 48 hours send an email with
							your eth address and wax address to account@redfoxlabs.io
						</p>
						<h2 style={{ textAlign: "center" }}>
							Balance: {erc20balance ? erc20balance : 0} {config.defaultToken}
						</h2>
						<div className="box-container">
							<Box
								type="booster"
								available={availableBooster}
								setAmount={boosterAmount}
								waxAddress={boosterWaxAddress}
								onChange={(value) => this.allocateBooster(value)}
								onChangeAddress={(value) =>
									this.setState({ boosterWaxAddress: value })
								}
								onBurn={() =>
									boosterAmount > 0
										? boosterWaxAddress.length > 0
											? this.setState({
													confirmationPopup: {
														type: "booster",
														amount: boosterAmount,
														rewardAmount: boosterAmount,
														ethAddress: user.EthId,
														waxAddress: boosterWaxAddress,
													},
											  })
											: alert("Please Input Target WAX address")
										: alert("Please Input the Amount")
								}
							/>
							<Box
								type="starter"
								available={availableStarter}
								setAmount={starterAmount}
								waxAddress={starterWaxAddress}
								onChange={(value) => this.allocateStarter(value)}
								onChangeAddress={(value) =>
									this.setState({ starterWaxAddress: value })
								}
								onBurn={() =>
									starterAmount > 0
										? starterWaxAddress.length > 0
											? this.setState({
													confirmationPopup: {
														type: "starter",
														amount: starterAmount * 2,
														rewardAmount: starterAmount,
														ethAddress: user.EthId,
														waxAddress: starterWaxAddress,
													},
											  })
											: alert("Please Input Target WAX address")
										: alert("Please Input the Amount")
								}
							/>
						</div>
					</div>
				) : (
					<Redirect to={{ pathname: "/notfound" }} />
				)}
				{confirmationPopup && (
					<ConfirmationPopup
						open={confirmationPopup ? true : false}
						type={confirmationPopup.type}
						amount={confirmationPopup.amount}
						rewardAmount={confirmationPopup.rewardAmount}
						ethAddress={confirmationPopup.ethAddress}
						waxAddress={confirmationPopup.waxAddress}
						onConfirm={() => {
							this.props.burnERC20Token(
								confirmationPopup.ethAddress,
								confirmationPopup.amount,
								confirmationPopup.type,
								confirmationPopup.waxAddress
							);
							this.setState({ confirmationPopup: null });
						}}
						onClose={() => this.setState({ confirmationPopup: null })}
					/>
				)}
				<LoadingIndicator open={burningInProgress} message="Burning ..." />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.authReducer.user,
	erc20balance: state.transactionReducer.erc20balance,
	burningInProgress: state.transactionReducer.burningInProgress,
});

const mapDispatchToProps = (dispatch) => ({
	getERC20TokenBalance: (walletAddress, tokenName) =>
		dispatch(getERC20TokenBalance(walletAddress, tokenName)),
	burnERC20Token: (walletAddress, amount, boxType, waxAddress) =>
		dispatch(burnERC20Token(walletAddress, amount, boxType, waxAddress)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Inventory);
