import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./home.scss";

class Home extends Component {
	render() {
		const { user } = this.props;
		return (
			<div className="page-container">
				<div className="home-view">
					<h1 style={{ fontSize: "58px" }}>Create your RedFOX Account</h1>
					<p>
						<img
							src={require("assets/images/image-logo.svg")}
							style={{ width: "100px" }}
							alt="logo"
						/>
					</p>
					{!user && (
						<p style={{ fontSize: "28px" }}>
							<span>
								<Link to={{ pathname: "/register" }} style={{ color: "white" }}>
									Sign Up
								</Link>
							</span>
							{" to link your WAX and ETH accounts"}
						</p>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Home);
