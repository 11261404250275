import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Dashboard extends Component {
	render() {
		const user = this.props.user;
		return (
			<div className="page-container">
				{user ? (
					<div className="page-viewport-area">
						<h1 style={{ fontSize: "26px" }}>Here is Dashboard</h1>

						<div>
							<h2>Congratulations!</h2>
							<h4>Only signed users can see this page!</h4>
						</div>
					</div>
				) : (
					<Redirect to={{ pathname: "/notfound" }} />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);
