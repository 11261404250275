import React from "react";

import "./authButton.scss";

function getAuthMethodTitle(method, isRegister) {
	let methodName = "";
	switch (method) {
		case "email":
			methodName = "EMAIL";
			break;
		case "facebook":
			methodName = "FACEBOOK";
			break;
		case "google":
			methodName = "GOOGLE";
			break;
		case "wax":
			methodName = "WAX CLOUD WALLET";
			break;
		case "metamask":
			methodName = "METAMASK";
			break;
		default:
			break;
	}
	return `SIGN ${isRegister ? "UP" : "IN"} WITH ${methodName}`;
}

function AuthButton({ method, isRegister, onClick }) {
	const isClickable = !(method === "facebook" || method === "google");
	return (
		<div
			className="auth-button"
			onClick={() => (isClickable ? onClick() : null)}
		>
			<div className="auth-button-logo">
				<img
					className="logo-image"
					src={require(`assets/images/authbtns/auth-${method}.svg`)}
					alt="method"
				/>
			</div>
			<div className="auth-button-text">
				{getAuthMethodTitle(method, isRegister)}
			</div>
		</div>
	);
}

export default AuthButton;
