import { put, takeLatest, call, select } from "redux-saga/effects";
import * as ActionTypes from "store/constants";

import { loginWax, getAuthenticators } from "api/wax";
import {
	connectWax,
	disconnectWax,
	connectEth,
	disconnectEth,
} from "api/connectionAPI";
import {
	setWax,
	unsetWax,
	setEth,
	unsetEth,
} from "store/actions/connectionActions";

import jwt_decode from "jwt-decode";

import { getMetamaskAccount } from "api/web3client";
import { showAuthMessage } from "store/actions";

//ETH
function* workerConnectETH() {
	try {
		const state = yield select();
		const { user } = state.authReducer;

		const EthId = yield call(getMetamaskAccount);

		const status = yield call(connectEth, user.UserId, EthId);
		if (status.status === 200) {
			localStorage.setItem("jwtToken", status.data.accessToken);
			const decoded = jwt_decode(status.data.accessToken);
			yield put(setEth(decoded.payload.EthId));
		} else if (status.status === 409) {
			yield put(
				showAuthMessage(
					"Your Metamask account is already associated with other account.",
					false
				)
			);
		}
	} catch (error) {
		console.error();
	}
}
function* workerDisconnectETH() {
	try {
		const state = yield select();
		const { user } = state.authReducer;

		const status = yield call(disconnectEth, user.UserId);
		if (status.status === 200) {
			localStorage.setItem("jwtToken", status.data.accessToken);
			yield put(unsetEth());
		}
	} catch (error) {
		console.error();
	}
}

//WAX
function* workerConnectWAX() {
	try {
		const state = yield select();
		const { ual, user } = state.authReducer;

		//Getting Authenticators
		const authenticator = getAuthenticators(ual)["wax"];
		const { WaxId } = yield call(loginWax, authenticator[0]);

		const status = yield call(connectWax, user.UserId, WaxId);
		if (status.status === 200) {
			localStorage.setItem("jwtToken", status.data.accessToken);
			const decoded = jwt_decode(status.data.accessToken);
			yield put(setWax(decoded.payload.WaxId));
		} else if (status.status === 409) {
			console.log("here");
			yield put(
				showAuthMessage(
					"Your WAX account is already associated with other account.",
					false
				)
			);
		}
	} catch (error) {
		console.error();
	}
}

function* workerDisconnectWAX() {
	try {
		const state = yield select();
		const { user } = state.authReducer;

		const status = yield call(disconnectWax, user.UserId);
		if (status.status === 200) {
			localStorage.setItem("jwtToken", status.data.accessToken);
			yield put(unsetWax());
		}
	} catch (error) {
		console.error();
	}
}

export default function* workConnectionSaga() {
	yield takeLatest(ActionTypes.CONNECT_METAMASK, workerConnectETH);
	yield takeLatest(ActionTypes.DISCONNECT_METAMASK, workerDisconnectETH);
	yield takeLatest(ActionTypes.CONNECT_WAX, workerConnectWAX);
	yield takeLatest(ActionTypes.DISCONNECT_WAX, workerDisconnectWAX);
}
