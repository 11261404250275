import React from "react";

const styles = {
	burger: {
		width: "40px",
		padding: "13px 9px",
		boxSizing: "border-box",
	},
	line: {
		height: "2px",
		backgroundColor: "#c12026",
		margin: "5px 0px",
	},
};
function BurgerButton({ onClick }) {
	return (
		<ul style={styles.burger} onClick={() => onClick()}>
			<div style={styles.line} />
			<div style={styles.line} />
			<div style={styles.line} />
		</ul>
	);
}

export default BurgerButton;
