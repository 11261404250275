import * as ActionTypes from "store/constants";

//Metamask
export const connectMetamask = () => {
	return {
		type: ActionTypes.CONNECT_METAMASK,
	};
};
export const disconnectMetamask = () => {
	return {
		type: ActionTypes.DISCONNECT_METAMASK,
	};
};
export const setEth = (EthId) => {
	return {
		type: ActionTypes.SET_ETH,
		EthId,
	};
};
export const unsetEth = () => {
	return {
		type: ActionTypes.UNSET_ETH,
	};
};

//Wax
export const connectWAX = () => {
	return {
		type: ActionTypes.CONNECT_WAX,
	};
};
export const disconnectWAX = () => {
	return {
		type: ActionTypes.DISCONNECT_WAX,
	};
};

export const setWax = (WaxId) => {
	return {
		type: ActionTypes.SET_WAX,
		WaxId,
	};
};
export const unsetWax = () => {
	return {
		type: ActionTypes.UNSET_WAX,
	};
};
