import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
} from "@material-ui/core";
import { signinWithEmail } from "api/authAPI";
import { showAuthMessage } from "store/actions";

import LoadingIndicator from "components/LoadingIndicator";

const DialogStyle = {
	style: {
		backgroundColor: "#202020",
		color: "white",
	},
};

class EmailSignin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
		};
	}

	componentWillReceiveProps() {
		this.setState({
			email: "",
			password: "",
			inSigninProgress: false,
		});
	}
	async confirm() {
		const { email, password } = this.state;

		this.setState({ inSigninProgress: true });
		const signinData = await signinWithEmail(email, password);

		console.log(signinData);
		if (signinData.status === 401) {
			if (signinData.error === "wrong_password")
				this.props.showAuthMessage("Password is not correct.", false);
			if (signinData.error === "not_found")
				this.props.showAuthMessage("Email not found.", false);
		}

		this.setState({ inSigninProgress: false });
	}
	render() {
		const { email, password, inSigninProgress } = this.state;
		const { open, onClose } = this.props;

		return (
			<Dialog open={open} PaperProps={DialogStyle}>
				<DialogTitle>
					<div className="popup-header">
						<img
							src={require("assets/images/authbtns/auth-email.svg")}
							alt="metamask"
							style={{ width: "50px", margin: "auto" }}
						/>
					</div>
				</DialogTitle>
				<DialogContent>
					<div
						style={{
							textAlign: "center",
							display: "flex",
							flexDirection: "column",
							width: "250px",
						}}
					>
						<input
							className="defaultInput"
							placeholder="Email"
							value={email}
							onChange={(e) => this.setState({ email: e.target.value })}
						/>
						<input
							className="defaultInput"
							placeholder="Password"
							type="password"
							value={password}
							onChange={(e) => this.setState({ password: e.target.value })}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button style={{ color: "white" }} onClick={() => this.confirm()}>
						Sign In
					</Button>
					<Button onClick={onClose} style={{ color: "white" }}>
						Cancel
					</Button>
				</DialogActions>
				<div>
					<LoadingIndicator open={inSigninProgress} />
				</div>
			</Dialog>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	showAuthMessage: (message, status) =>
		dispatch(showAuthMessage(message, status)),
});

export default connect(
	null,
	mapDispatchToProps
)(EmailSignin);
