import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import AuthButton from "components/AuthButton";

import { registerWithMetamask, hideAuthMessage } from "store/actions";
import { isMetamaskConnected } from "api/web3client";

import MetamaskConfirmation from "./Popups/metamask";
import EmailRegistrationPopup from "./Popups/emailRegistration";
import LoadingIndicator from "components/LoadingIndicator";

import {
	NotificationContainer,
	NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import "./register.scss";

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			metamaskAccount: false,
			emailRegistrationPopup: false,
		};
	}

	componentDidUpdate() {
		const { authMessage } = this.props.authReducer;
		if (authMessage.message) {
			setTimeout(() => {
				this.props.hideAuthMessage();
			}, 500);
		}
	}

	async registerWithMetamask() {
		const metamaskAccount = await isMetamaskConnected();
		if (metamaskAccount) this.setState({ metamaskAccount: metamaskAccount });
		else this.props.registerWithMetamask();
	}

	render() {
		const { metamaskAccount, emailRegistrationPopup } = this.state;
		const { isRegistering, authMessage, user } = this.props.authReducer;

		return (
			<div className="page-container">
				{!user ? (
					<div>
						<div className="mode-view">
							<img
								src={require("assets/images/image-logo.svg")}
								className="image-logo"
								alt="logo"
							/>
							<h2>Sign Up to RedFOX Labs</h2>
							<div>
								{/*<AuthButton
									method="email"
									isRegister={true}
									onClick={() =>
										this.setState({ emailRegistrationPopup: true })
									}
								/>
								<a
									href={`${
										process.env.REACT_APP_SERVER_URL
									}/auth/authWithGoogle`}
									className="disabledLink"
								>
									<AuthButton method="google" isRegister={true} />
								</a>
								<a
									href={`${
										process.env.REACT_APP_SERVER_URL
									}/auth/authWithFacebook`}
									className="disabledLink"
								>
									<AuthButton method="facebook" isRegister={true} />
								</a>*/}
								<AuthButton
									method="metamask"
									isRegister={true}
									onClick={() => this.registerWithMetamask()}
								/>
							</div>
							<br />
							<hr />
							<p>
								Have a RedFOX account? &nbsp;&nbsp;
								<span>
									<Link to={{ pathname: "/signin" }} className="text-link">
										Sign In
									</Link>
								</span>
							</p>
						</div>
						<div>
							<MetamaskConfirmation
								open={metamaskAccount !== false}
								account={metamaskAccount}
								onRegister={() => {
									this.setState({ metamaskAccount: false });
									this.props.registerWithMetamask();
								}}
								onClose={() => this.setState({ metamaskAccount: false })}
							/>
							<EmailRegistrationPopup
								open={emailRegistrationPopup}
								onRegister={() => {
									this.setState({ emailRegistrationPopup: false });
								}}
								onClose={() => this.setState({ emailRegistrationPopup: false })}
							/>

							<LoadingIndicator open={isRegistering} />
							{authMessage.message &&
								(authMessage.succeed
									? NotificationManager.success(authMessage.message)
									: NotificationManager.error(authMessage.message))}
							<NotificationContainer />
						</div>{" "}
					</div>
				) : (
					<Redirect to={{ pathname: "/" }} />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	authReducer: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
	registerWithMetamask: () => dispatch(registerWithMetamask()),
	hideAuthMessage: () => dispatch(hideAuthMessage()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Register);
