import React, { Component } from "react";

class NotFound extends Component {
	render() {
		return (
			<div className="page-container">
				<div style={{ textAlign: "center", padding: "100px" }}>
					<h1>404</h1>
					<div>
						<h2>Ooops! Your Princess Is Not Here!</h2>
					</div>
				</div>
			</div>
		);
	}
}

export default NotFound;
