import * as ActionTypes from "store/constants";

const initialState = {
	erc20balance: null,
	burningInProgress: false,
};

export default function setBrowserInfo(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_ERC20_BALANCE:
			return {
				...state,
				erc20balance: action.payload,
			};
		case ActionTypes.BURN_ERC20:
			return {
				...state,
				//				burningInProgress: true,
			};
		case ActionTypes.BURNING_FINISHED:
			return {
				...state,
				burningInProgress: false,
			};
		default:
			return state;
	}
}
