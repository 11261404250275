import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { signout } from "store/actions";

import "./header.scss";

function MobileMenu({ user, signout, history, closeMenu }) {
	return (
		<div className="mobile-header-container">
			<Link to={{ pathname: "/" }}>
				<img
					src={require("assets/images/main-logo.svg")}
					className="main-logo"
					alt="main-logo"
				/>
			</Link>
			<div className="header-menu">
				{user && (
					<Link
						to={{ pathname: "/inventory" }}
						className="menu-item"
						onClick={closeMenu}
					>
						INVENTORY
					</Link>
				)}
				{user && (
					<Link
						to={{ pathname: "/connections" }}
						className="menu-item"
						onClick={closeMenu}
					>
						CONNECTIONS
					</Link>
				)}
				<div className="menu-item">
					{!user ? (
						<Link
							to={{ pathname: "/register" }}
							onClick={closeMenu}
							className="menu-item"
						>
							<i className="fa fa-user-circle-o" style={{ color: "red" }} />
							&nbsp;SIGN UP
						</Link>
					) : (
						<div
							onClick={() => {
								signout();
								closeMenu();
								history.push("/");
							}}
						>
							SIGN OUT
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
	signout: () => dispatch(signout()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(MobileMenu));
